body {
  margin: 1rem;
  font-family: 'Ubuntu Mono', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #282a35;
  color: #fdf9f2;
  font-size: 18px;
  line-height: 1.125;
}
a {
  color: #93fba3;
  text-decoration: none;
}
a:hover,
a.active{
  background-color: #93fba3;
  color: #282a35;
}
button{
  color: #93fba3;
  text-decoration: none;
  background: none;
  border: none;
  font-family: 'Ubuntu Mono', monospace;
  font-size: 1.125rem;
  padding: 0;
  cursor: pointer;
}
button:hover,
button.active{
  background-color: #93fba3;
  color: #282a35;
}
section{
  margin: 1.125rem 0;
}
section::before {
  content: '=========== \A \A';
  white-space: pre;
}
section::after {
  content: '';
  white-space: pre;
}
p{
  line-height: 1.125;
  margin: 0 0 1.125em 0;
}
h1, h2, h3, h4, h5 {
  font-size: 1em;
  line-height: 1.125;
  margin: 0 0 1.125em 0;
  text-transform: uppercase;
}
pre{
  display: block;
  height: 200px;
}
.cvEvent a::after {
    content: '\00a0>\00a0';
}
.cvEvent a:hover::after {
    content: '\00a0->';
}
nav a::after,
nav button::after{
  content: ' ';
  white-space: pre;
}
nav a::before,
nav button::before{
  content: ' ';
  white-space: pre;
}